html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

a {
    text-decoration: none
}

body {
    background-color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-family: Helvetica, Arial, Tahoma, sans-serif;
    font-weight: 400;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    color: #000
}

.red {
    color: #a12528
}

strong {
    font-weight: 700
}

.container {
    width: 100%;
    max-width: 1063px;
    margin: 0 auto;
    padding: 0 10px
}

img {
    max-width: 100%;
    height: auto
}

.container img {
    max-width: 100%;
    height: auto
}

.container:after {
    content: '';
    display: block;
    clear: both;
    float: none
}

.alignleft {
    float: left
}

.alignright {
    float: right
}

.aligncenter {
    float: none;
    text-align: center
}

.col-md-7 {
    width: calc(58.33333333% - 20px);
    padding-left: 10px;
    padding-right: 10px
}

.col-md-5 {
    width: calc(40.66666667% - 20px);
    padding-left: 10px;
    padding-right: 10px
}

.col {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    vertical-align: top
}

.col:after {
    content: '';
    clear: both;
    float: none;
    display: block
}

.col-8 {
    width: calc(100% - 365px);
    padding-right: 15px
}

.col-4 {
    width: 340px
}

.col-2 {
    width: 105px
}

.col-wrapper {
    width: calc(100% - 210px);
    padding: 0 195px 0 15px
}

.no-padding .col-wrapper {
    width: 100%;
    padding: 0
}

.top {
    width: 100%;
    height: 54px;
    text-align: center
}

.top .container {
    max-width: 1102px
}

img.logo.alignleft {
    margin: 24px 0
}

.header {
    display: block;
    width: 100%;
    background: #fff
}

.menu-wrap {
    margin: 0;
    padding: 0;
    vertical-align: bottom
}

.menu {
    width: auto;
    display: inline-block;
    vertical-align: bottom
}

.tablet,
.mobile {
    display: none
}

.contents {
    margin-top: 10px
}

.social.aligncenter {
    width: 100%;
    height: auto;
    margin: 0 0 10px
}

.published {
    color: #999;
    font-size: 12px;
    line-height: 12px;
    margin-top: 10px;
    display: block
}

.article-title {
    letter-spacing: 0;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    color: #21409a;
    display: inline-block;
    margin: 16px 0;
    text-align: center
}

.featured-image {
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;
    display: inline-block
}

p {
    font-size: 1em;
    line-height: 30px;
    margin-bottom: 25px;
    color: #333
}

.emotify-header {
    padding: 6px;
    border-bottom: 1px solid #d8d8d8;
    background: #e8e8e8;
    border-radius: 2px;
    overflow: hidden;
    margin: 40px 0 15px
}

.cta-button {
    color: #fff;
    background-color: #21409a;
    padding: 10px 30px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    border: 1px solid #11409a;
    margin: 5px 0 20px
}

.cta-button:hover {
    background: #3d60c5
}

.fbcommentscontainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto
}

.fbConnectWidgetFooter {
    bottom: 0;
    cursor: default;
    position: absolute;
    width: 100%
}

.fbConnectWidgetFooter .fbFooterBorder {
    border-top: 1px solid #ccc;
    margin: 0 6px;
    padding: 4px 3px
}

.DOMControl_placeholder {
    color: #777
}

.fbFeedbackContent {
    -webkit-text-size-adjust: none
}

.fbFeedbackContent .composerHider {
    border-bottom: 0 solid #ccc;
    border-top: 1px solid #ccc;
    margin: 0;
    padding: 9px 10px 7px
}

.fbFeedbackContent .composerHider .uiSelector {
    margin: -3px 1px
}

.fbFeedbackContent .composerHider .uiSelector .uiSelectorButton,
.fbFeedbackContent .composerHider .uiSelector .uiSelectorButton:active,
.fbFeedbackContent .composerHider .uiSelector .uiSelectorButton:focus,
.fbFeedbackContent .composerHider .uiSelector .uiSelectorButton:hover {
    background-repeat: no-repeat;
    background-position: right -202px;
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    margin: 0;
    outline: 0;
    padding: 2px 20px 2px 0
}

.fbFeedbackContent .composerHider a {
    font-weight: 700
}

.fbFeedbackContent .indicator {
    float: left;
    margin: 10px 0 0 -45px;
    position: absolute
}

.fbFeedbackContent .postContent .action_links {
    margin-bottom: 8px
}

.fbFeedbackContent .postBlingBox {
    border: none;
    bottom: 1px;
    cursor: text;
    padding: 0;
    position: relative
}

.fbFeedbackContent .postBlingBox:hover {
    background-color: transparent;
    border: none
}

.fbFeedbackContent {
    min-height: 160px
}

.fbFeedbackContent .fbFeedbackPosts {
    padding: 0 0 0 10px
}

.fbFeedbackContent .profileName {
    font-size: 11px
}

.fbFeedbackContent .fbFeedbackPost {
    border: solid #e2e2e2;
    border-width: 1px 0 0;
    clear: both;
    padding-bottom: 0 !important;
    padding-top: 10px
}

.fbFeedbackContent li.fbTopLevelComment:first-of-type {
    border-width: 0
}

.fbFeedbackContent li.fbTopLevelComment:last-of-type {
    margin-bottom: -5px
}

.fbFeedbackReplies .fbFeedbackPost {
    border-width: 0 0 1px;
    border-color: #e9e9e9
}

.postReplies {
    margin-top: 8px
}

.fbFeedbackPost {
    position: relative
}

.fbFeedbackPost .postContent {
    margin-top: 5px
}

.fbFeedbackPost .postText {
    color: #1a1a1a;
    line-height: 14px;
    margin: 0 28px 5px 0
}

.fbFeedbackPost .postActor {
    background-color: #ededed;
    margin-bottom: 8px;
    margin-right: 11px
}

.fbFeedbackContent .fbConnectWidgetFooter {
    position: static
}

.fbFeedbackContent .fbConnectWidgetFooter .fbFooterBorder {
    margin: 5px 0 0
}

.fbTopLevelComment .fbFeedbackReplies .fbCommentReply {
    border-width: 1px 0 0
}

.uiHeader h4 {
    color: #333;
    font-size: 11px
}

.uiHeader .uiHeaderTitle {
    outline: 0
}

.uiHeaderTopBorder {
    border-top: 1px solid #aaa;
    padding-top: .5em
}

div.uiHeaderTopBorder {
    margin-left: 0
}

.uiHeaderNav {
    border-color: #eee;
    margin: 8px 0 0 6px;
    padding: 7px 6px 3px 5px
}

.uiHeaderNav h4 {
    color: gray
}

.lfloat {
    float: left
}

.phm {
    padding-left: 10px;
    padding-right: 10px
}

.sp_comments {
    background-image: url(./images/like.png);
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    width: 16px
}

.sx_comments_cfavicon {
    width: 14px;
    height: 14px;
    background-position: 0 -187px
}

.sx_comments_like {
    width: 15px;
    height: 13px;
    background-position: -33px -170px
}

.uiBlingBox {
    border-bottom: 1px solid #fff;
    display: inline-block;
    padding: 3px 4px 3px 2px
}

.uiBlingBox:hover {
    text-decoration: none;
    background-color: #eceff5;
    border-bottom: 1px solid #e5eaf1
}

.uiBlingBox .text {
    line-height: 11px;
    vertical-align: text-bottom
}

.uiBlingBox .img {
    margin-right: 2px;
    vertical-align: text-top
}

.uiButton {
    cursor: pointer;
    display: inline-block;
    font-size: 11px;
    font-weight: 700;
    line-height: 13px;
    padding: 2px 6px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap
}

.uiButton,
.uiButtonSuppressed:active,
.uiButtonSuppressed:focus,
.uiButtonSuppressed:hover {
    background-repeat: no-repeat;
    background-position: -352px -348px;
    background-color: #eee;
    border: 1px solid #999;
    border-bottom-color: #888;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .1)
}

.uiButton:hover {
    text-decoration: none
}

.uiButton:active {
    background: #ddd;
    border-bottom-color: #999;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .05)
}

.uiButtonSuppressed {
    background: 0 0;
    border-color: transparent;
    -webkit-box-shadow: none
}

.uiImageBlockContent {
    overflow: hidden
}

.uiImageBlockSmallContent {
    padding-top: 1px
}

.uiImageBlockImage,
.uiImageBlockImage .img {
    display: block
}

.uiImageBlockSmallImage {
    margin-right: 5px
}

a.uiLinkSubtle {
    color: gray
}

.uiListItem {
    display: block
}

.uiListVerticalItemBorder {
    border-width: 1px 0 0
}

.uiListItem:first-child {
    border-width: 0
}

.uiToggleFlyout {
    display: none
}

.uiSelector {
    max-width: 200px;
    vertical-align: top
}

.uiSelector .wrap {
    position: relative
}

.uiSelector .uiButton,
.uiSelector .uiButton:active,
.uiSelector .uiButton:focus,
.uiSelector .uiButton:hover {
    background-image: none
}

.uiSelector .uiSelectorButton {
    vertical-align: top
}

.uiSelector .uiSelectorButton:active {
    background-color: #ddd
}

.uiSelectorNormal .uiButtonSuppressed:active,
.uiSelectorNormal .uiButtonSuppressed:focus,
.uiSelectorNormal .uiButtonSuppressed:hover,
.uiSelectorNormal .uiSelectorButton,
.uiSelectorNormal .uiSelectorButton:active,
.uiSelectorNormal .uiSelectorButton:focus,
.uiSelectorNormal .uiSelectorButton:hover {
    background-repeat: no-repeat;
    background-position: right -5px;
    max-width: 169px;
    padding-right: 23px
}

.uiSelectorNormal .uiButtonSuppressed,
.uiSelectorNormal .uiSelectorButton:active {
    background-repeat: no-repeat;
    background-position: right -202px
}

.uiSelectorMenuWrapper {
    bottom: auto;
    left: 0;
    margin: -1px 0 30px;
    max-width: 340px;
    min-width: 100%;
    position: absolute;
    right: auto;
    top: 100%;
    z-index: -1
}

.uiTextareaNoResize {
    resize: none
}

.uiTextareaAutogrow {
    overflow: hidden
}

.fss {
    font-size: 9px
}

.fsm {
    font-size: 11px
}

.fsl {
    font-size: 13px
}

.fwn {
    font-weight: 400
}

.fwb {
    font-weight: 700
}

.fcb {
    color: #333
}

.fcg {
    color: gray
}

.UIImageBlock_Content {
    display: table-cell;
    vertical-align: top;
    width: 10000px
}

.UIImageBlock_Image {
    float: left;
    margin-right: 5px
}

.UIImageBlock_Image .img {
    display: block
}

.UIImageBlock_MED_Image {
    margin-right: 10px
}

.fbcommentscontainer a {
    color: #3b5998 !important
}

.sp_comments {
    background-image: url(./images/like.png);
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    width: 16px;
    background-size: 100%;
    background-position: 0
}

.sidebar.col-4 {
    padding-top: 50px
}

.sidebar.col-4 img {
    width: 340px;
    height: auto
}

.footer {
    background: #f0f0f0;
    text-align: left;
    padding: 50px 0;
    border-top: 4px solid #21409a;
    margin-top: 40px
}

@media(max-width:1101px) {
    .top .container {
        max-width: 1102px
    }
}

@media(max-width:1062px) {
    .container {
        max-width: calc(100% - 10px);
        width: calc(100% - 10px);
        padding: 0 5px
    }
}

@media(max-width:1023px) {
    .col-4 {
        width: 200px
    }
    .col-8 {
        width: calc(100% - 222px)
    }
}

@media(max-width:767px) {
    .mobile {
        display: block
    }
    .mobile img {
        width: 100%;
        height: auto
    }
    .sidebar.col-4,
    .desktop,
    .footer,
    .bottom-content {
        display: none
    }
    .col-8 {
        width: 100%;
        padding: 0
    }
}